import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Button, Spinner } from 'react-bootstrap';
import { AnyObject } from 'react-final-form';
import moment from 'moment';
import { IRootState } from '../../shared/reducers';
import { LearnerFilesContainer, FilesListContainer } from './styled-component';
import FileUploadForm from './file-upload-modal/file-upload-modal';
import UploadedFilesTable from './uploaded-files-list/uploaded-files-list';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import { WithLoading } from '../helper-components/loading.component';
import AddDocumentErrorNotification from './add-document-error-notification';
import { getLearnerAepDocumentsInfo, updateLearnerAepDocumentsInfo } from '../../shared/actions/aep-documents.action';
import { getAepDocumentsState } from '../../shared/selectors/aep-documents.selector';

import {
  getAdmissionsFiles,
  getLearnerFiles,
  getApplicantFiles,
  getCategoryOptions,
  sortFilesByUploadDate,
  createAcceptDocumetsModel as createAcceptDocumentsModel,
} from './learner-files-functions';
import fileUploadService, {
  UploadFileType,
} from '../../shared/uploads/upload-service';
import { createOptions, isCurrentDate } from '../helper-components/form-components/form-filed-components/form-filed.components';
import { getApplicantInfo } from '../admissions/admissions-functions';
import errorHandling from '../helper-components/alert-component.component';
import api, {
  IUploadFormData, IUploadData, LEARNER_FILE_TYPE, APPLICANT_FILE_TYPE,
} from '../../shared/api/adminUI.api';
import {
  IDispatchProps,
  ILearnerFilesState,
  ILearnerFilesStateProps,
  IFileUploadResponse,
  ILearnerFilePermissions,
  ICommonProps,
  IUpdateAepDocumentsRequest,
} from './learner-files.model';
import { IUploadedFile } from '../../models/UploadedFile';
import AEPOptionsDialog from './aep-files-modal/aep-files-modal';
import { OK } from '../admissions/admissions-form/admissions-form.constants';

const ENROLLED = 'Enrolled';

class LearnerFiles extends React.Component<ICommonProps, ILearnerFilesState> {
  constructor(props: ICommonProps) {
    super(props);
    const defaultPermissions: ILearnerFilePermissions = {
      canEditLearnerFile: false,
    };
    this.state = {
      showUploadModal: false,
      showAepOptionsModal: false,
      aepUpdatePending: false,
      addDocumentPending: false,
      categoryOptionsPending: false,
      fileListPending: false,
      fileUploadPending: false,
      uploadedFiles: [],
      categoryOptions: [],
      error: null,
      learnerFilePermissions: defaultPermissions,
    };
  }

  public componentDidMount(): void {
    const {
      learnerId, getBearerToken, isAdmission, getAEPDocumentsData, editAepOnUpload,
    } = this.props;

    this.getCategoriesOptions(isAdmission, getBearerToken);

    this.getFilesList(learnerId, getBearerToken, isAdmission);
    if (!isAdmission && editAepOnUpload) {
      getAEPDocumentsData(getBearerToken, learnerId);
    }

    this.getPermissions(getBearerToken);
  }

  // eslint-disable-next-line max-len
  private getPermissions = async (getBearerToken: () => Promise<string>) : Promise<ILearnerFilePermissions> => {
    const permissionsData = await api.documentUpload.getLearnerFilePermission(
      getBearerToken,
    );
    if (!permissionsData.ok) throw await permissionsData;

    const permissions = (await permissionsData.json()) as ILearnerFilePermissions;

    this.setState({ learnerFilePermissions: permissions });
    return permissions;
  };

  private deleteFileHandler = (fileName: string) => {
    const { uploadedFiles } = this.state;
    const newList = uploadedFiles
      .filter((item) => item.fileName !== fileName);

    this.setState({ uploadedFiles: newList });
  };

  private updateFileHandler = (learnerId: string, file: IUploadedFile) => {
    const { uploadedFiles } = this.state;
    const newList = uploadedFiles.map((item) => {
      if (item.fileName === file.fileName) {
        const updatedItem:IUploadedFile = {
          ...item,
          displayFileName: file.displayFileName,
          category: file.category,
          subCategory: file.subCategory,
          comment: file.comment,
        };

        return updatedItem;
      }

      return item;
    });

    this.setState({ uploadedFiles: newList });
  };

  private handleUploadModal = async (showUploadModal: boolean, isAdmission?: boolean) => {
    if (isAdmission) {
      const { getBearerToken, learnerId } = this.props;
      try {
        this.setState({ addDocumentPending: true });
        const applicantInfo = await getApplicantInfo(learnerId, getBearerToken);
        if (applicantInfo.Status === ENROLLED) {
          this.setState({ addDocumentPending: false });
          AddDocumentErrorNotification({ learnerId });
        } else {
          this.setState({ showUploadModal, addDocumentPending: false });
        }
      } catch (error) {
        errorHandling({ error });
        this.setState({ addDocumentPending: false });
      }
    } else {
      this.setState({ showUploadModal });
    }
  };

  private handleAEPModal = async () => {
    this.setState({ showAepOptionsModal: false });
  };

  private getCategoriesOptions = async (
    isAdmission: boolean,
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    try {
      this.setState({ categoryOptionsPending: true });
      const categoryOptions = await getCategoryOptions(isAdmission, getBearerToken);
      this.setState({
        categoryOptions: createOptions(categoryOptions),
        categoryOptionsPending: false,
      });
    } catch (error) {
      this.setState({ categoryOptionsPending: false, error });
      errorHandling({ error });
    }
  };

  private getFilesList = async (
    learnerId: string,
    getBearerToken: () => Promise<string>,
    isAdmission: boolean,
  ): Promise<void> => {
    try {
      this.setState({ fileListPending: true });
      let learnerFilesList;
      if (isAdmission) {
        learnerFilesList = await getApplicantFiles(learnerId, getBearerToken);
      } else {
        const admissionFiles = await getAdmissionsFiles(learnerId, getBearerToken);
        const learnerFiles = await getLearnerFiles(learnerId, getBearerToken);
        learnerFilesList = [...learnerFiles, ...admissionFiles];
      }

      this.setState({
        uploadedFiles: sortFilesByUploadDate(learnerFilesList), fileListPending: false,
      });
    } catch (error) {
      this.setState({ fileListPending: false });
      errorHandling({ error });
    }
  };

  private submitUploadForm = async (
    learnerId: string,
    getBearerToken: () => Promise<string>,
    fileType: string,
    uploadFormData: IUploadData,
  ): Promise<void> => {
    try {
      const response = await api.documentUpload.submitUploadForm(
        learnerId,
        getBearerToken,
        fileType,
        uploadFormData,
      );
      if (!response.ok) throw response;
      const learnerFile = await response.json();
      const learnerFileWithType = { fileType, ...learnerFile };
      this.setState((prevState) => ({
        fileUploadPending: false,
        showUploadModal: false,
        uploadedFiles: [learnerFileWithType, ...prevState.uploadedFiles],
      }));
    } catch (error) {
      this.setState({ fileUploadPending: false, showUploadModal: false });
      errorHandling({ error });
    }
  };

  private uploadFile = async (
    learnerId: string,
    getBearerToken: () => Promise<string>,
    isAdmission: boolean,
    file: File,
    type: UploadFileType,
    uploadFormData: IUploadFormData,
  ): Promise<void> => {
    const { uploadDate } = uploadFormData;
    try {
      this.setState({ fileUploadPending: true });

      const token = await getBearerToken();

      const fileType = isAdmission ? APPLICANT_FILE_TYPE : LEARNER_FILE_TYPE;

      const response = await fileUploadService.uploadFileByFileType(
        token,
        learnerId,
        fileType,
        type,
        file,
      );
      const fileUploadResponse = (await response) as IFileUploadResponse;
      const uploadedFileName = fileUploadResponse.fileName;
      const currentTime = `${new Date().getHours()}:${new Date().getMinutes().toString().padStart(2, '0')}`;

      const uploadData = {
        uploadedFileName,
        ...uploadFormData,
        uploadDate: `${moment(uploadDate).format('YYYY-MM-DD')} ${isCurrentDate(uploadDate) ? currentTime : ''}`,
      };

      this.submitUploadForm(learnerId, getBearerToken, fileType, uploadData);

      this.onFileUploaded();
    } catch (error) {
      this.setState({ fileUploadPending: false, showUploadModal: false });
      errorHandling({ error });
    }
  };

  private handleSave = (values: AnyObject) => {
    const { learnerId, getBearerToken, isAdmission } = this.props;
    const {
      uploadFile, fileName, uploadDate, categorySelect, subcategory, comment,
    } = values;
    const uploadedFile = uploadFile[0];
    const category = categorySelect.value;
    const uploadFormData: IUploadFormData = {
      fileName,
      uploadDate,
      category,
      subcategory,
      comment,
    };
    this.uploadFile(learnerId, getBearerToken, isAdmission, uploadedFile, category, uploadFormData);
  };

  private handleAepSave = async (values: AnyObject) => {
    this.setState({ aepUpdatePending: true });
    const { acceptedDocuments } = values;
    const acceptedAepDocuments = createAcceptDocumentsModel(acceptedDocuments);
    const { learnerId, getBearerToken } = this.props;
    const { updateAepDocumentsData, aepDocumetsData } = this.props;

    acceptedAepDocuments.aepDocuments.forEach((element) => {
      const index = aepDocumetsData.aepDocuments.findIndex((ad) => ad.name === element.name);
      aepDocumetsData.aepDocuments[index].status = OK;
    });
    updateAepDocumentsData(learnerId, getBearerToken, aepDocumetsData);
    this.setState({ aepUpdatePending: false, showAepOptionsModal: false });
  };

  private onFileUploaded = () => {
    const { editAepOnUpload } = this.props;

    if (editAepOnUpload) {
      const { aepDocumetsData } = this.props;

      const missedDocuments = aepDocumetsData.aepDocuments.filter((ad) => ad.status !== OK);
      const showAepOptionsModal = missedDocuments.length > 0;

      this.setState({ showAepOptionsModal });
    }
  };

  render(): JSX.Element {
    const {
      getBearerToken, learnerId, learnerStatus, isAdmission, aepDocumetsData: aepDocumentsData,
    } = this.props;
    const isDocumentUploadAllowed = learnerStatus && learnerStatus !== ENROLLED;
    const {
      showUploadModal,
      showAepOptionsModal,
      aepUpdatePending,
      categoryOptions,
      uploadedFiles,
      addDocumentPending,
      categoryOptionsPending,
      fileUploadPending,
      fileListPending,
      error,
      learnerFilePermissions,
    } = this.state;
    const missedDocuments = aepDocumentsData.aepDocuments.filter((ad) => ad.status !== OK);

    const isFilesListEmpty = uploadedFiles.length === 0;

    return (
      <WithErrorHandling error={error}>
        <LearnerFilesContainer>
          {(isDocumentUploadAllowed || !isAdmission)
          && (
          <Button
            variant="primary"
            onClick={
              () => this.handleUploadModal(true, isAdmission)
            }
          >
            Add Document
            {addDocumentPending && <Spinner size="sm" animation="border" />}
          </Button>
          )}
          <FileUploadForm
            handleClose={this.handleUploadModal}
            handleSave={this.handleSave}
            showUploadModal={showUploadModal}
            categoryOptions={categoryOptions}
            categoryOptionsPending={categoryOptionsPending}
            fileUploadPending={fileUploadPending}
          />
          {missedDocuments && missedDocuments.length > 0
          && (
          <AEPOptionsDialog
            handleClose={this.handleAEPModal}
            handleSave={this.handleAepSave}
            showModal={showAepOptionsModal}
            aepDocuments={missedDocuments}
            aepUpdatePending={aepUpdatePending}
          />
          )}
          <FilesListContainer>
            <WithLoading loading={fileListPending} loadingText="Loading file list ...">
              {
                isFilesListEmpty
                  ? <h4>No uploaded files yet</h4>
                  : (
                    <UploadedFilesTable
                      learnerId={learnerId}
                      getBearerToken={getBearerToken}
                      categoryOptions={categoryOptions}
                      uploadedFilesList={uploadedFiles}
                      fileDeletedEvent={this.deleteFileHandler}
                      fileUpdatedEvent={this.updateFileHandler}
                      learnerFilePermissions={learnerFilePermissions}
                    />
                  )
              }
            </WithLoading>
          </FilesListContainer>
        </LearnerFilesContainer>
      </WithErrorHandling>
    );
  }
}
const mapStateToProps = (state: IRootState): ILearnerFilesStateProps => ({
  aepDocumetsData: getAepDocumentsState(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => (
  {
    getAEPDocumentsData: (token: () => Promise<string>, learnerId: string):
    void => {
      dispatch(getLearnerAepDocumentsInfo(token, learnerId));
    },
    updateAepDocumentsData: (
      learnerId: string,
      token: () => Promise<string>,
      updateAepDocumentsData: IUpdateAepDocumentsRequest,
    ): void => {
      dispatch(updateLearnerAepDocumentsInfo(learnerId, token, updateAepDocumentsData));
    },

  });

export default connect(mapStateToProps, mapDispatchToProps)(LearnerFiles);
