import moment from 'moment';
import { AnyObject } from 'final-form';
import { ISelectOption } from '../models/SelectOptions';

const checkIfTodaysDayIsInRange = (startDate: string, disableStartDateAfterXDays: number) => {
  const eighthDaysAfterStartDate = moment(startDate).add(disableStartDateAfterXDays, 'days');
  return moment().isBetween(startDate, eighthDaysAfterStartDate);
};

export const composeValidators = (
  ...validators: any[]
) => (value: string, allValues: any) => validators.reduce((
  error: string,
  validator: any,
) => error || validator(value, allValues), undefined);

export const minLength = (min: number) => (value: string) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
export const required = () => (value: string) => (!value ? 'Required' : undefined);
export const commentRequired = (
  errorMessage: string,
) => (value: string) => (!value ? errorMessage : undefined);

export const requiredSubStatus = (option: ISelectOption, allValues: any) => {
  const requiredSubStatuses = ['dismissed', 'onLeaveOfAbsense', 'withdrawn'];
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return option.value === '-' && requiredSubStatuses.includes(programStatusValue)
    ? 'Please select sub status' : undefined;
};

export const requiredReason = (option: ISelectOption, allValues: any) => {
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return option.value === '-' && programStatusValue !== 'enrolled' ? 'Please select reason' : undefined;
};

export const commentRequiredProgramStatus = (value: string, allValues: any) => {
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return !value && programStatusValue !== 'enrolled' ? 'Please provide comment for program status change' : undefined;
};


export const commentRequiredStartDate = (
  daysBeforeNextPeriod: number,
  disableStartDateAfterXDays: number,
  startDate: null | string,
) : (value: string, allValues: AnyObject
  ) =>
  string | undefined => (value: string): string | undefined => {
  const now = moment();
  if (startDate) {
    const startDateFormated = moment(moment(startDate).format('DD MMM YYYY'));
    const daysBeforeStart = moment.duration(startDateFormated.diff(now)).asDays();
    const isLaterThenXDaysBeforeStart = daysBeforeStart <= daysBeforeNextPeriod;
    const isBetweeenStartDateAndEigthDaysAfter = checkIfTodaysDayIsInRange(
      startDate,
      disableStartDateAfterXDays,
    );
    return (isLaterThenXDaysBeforeStart
  || isBetweeenStartDateAndEigthDaysAfter) && !value ? 'Comment is required' : undefined;
  }
  return undefined;
};


export const selectRequired = (option: ISelectOption) => (option.value === '-' ? 'Option is empty' : undefined);

export const isEmptyValue = (option: ISelectOption) => (option.value === '-' ? null : option.value);

export const dataSelectorRequired = (value: string) => (value === null ? 'Please select date' : undefined);
