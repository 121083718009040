import styled from 'styled-components';

const StyledWrapperCreatableSelect = styled.div`
  &&& {
    .react-select__control--is-focused .react-select__placeholder {
      margin-left: 7px;
    }
  }
`;

export default StyledWrapperCreatableSelect;
